.admin-login {
    .container {
        background-image: url('../img/bg\ \(1\).svg');
        background-size: cover;
        background-position: center;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: rgb(255, 252, 252);
    }
}
body{
    /* background-color: #000000; */
    /* color: rgb(255, 252, 252); */
    background-color: #F9FAF5;
    overflow-x: hidden;

}
/* .login-card{
    background-color: #ffff;
}
.login-logo-under-text{
    font-family: M PLUS 1;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.27px;
    text-align: center;
    width: 237px;
}

.form-control{
    border: 1px solid #DADEDA;
    border-radius: 8px;
    height: 45px;
    width: 280px;
}
.form-label{
    float: left;
    font-family: M PLUS 1;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.27px
}

.card{
    padding-left: 20px;
    padding-right: 40px;
    border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.form-check{
    margin-top: 10px;
    float: left;
} */

.form-label {
    font-family: "M PLUS 1", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.27px
}

.login-logo-under-text {
    font-family: "M PLUS 1", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.27px;
    text-align: center;
    color: #6E7765;
}

.form-check-label {
    font-family: "M PLUS 1", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.27px;
    text-align: center;
}

#forgot-password {
    margin-left: 75px;
    font-family: "M PLUS 1", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.27px;
    text-align: right;
    color: #6E7765;
}

.login-form {
    width: 300px;
}

.admin {
    height: 100vh; 
    background-color: #F9FAF5;
}

/* Assuming you have a global CSS file */

.container-fluid {
    height: 100vh;
}

section {
    height: 100%;
    background-color: #F9FAF5;
}

.flex-grow-1 {
    flex-grow: 1;
}

.p-3 {
    padding: 1rem;
    /* Adjust padding as needed */
}

.logo-text {
    font-family: "M PLUS 1", sans-serif;
    font-size: 21px;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #000000;
}

.username-circle {
    background-color: #AEDE3E;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 9px;
    padding-bottom: 9px;
}

li {
    list-style: none;
}

.user-management-table-heading {
    background-color: #AEDE3E;
    font-family: "M PLUS 1", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 18.82px;
    text-align: left;
    color: #313D15;
}

.user-management-table-detail {
    font-family: "M PLUS 1", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 17.38px;
    text-align: left;
    color: #313D15
}

ul {
    padding: 0px !important;
    /* margin-left: 20px; */
}

a {
    text-decoration: none;
    font-family: "M PLUS 1", sans-serif;
    ;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.03em;
    text-align: left;
    color: #313D15;
}

.btn-primary {
    background-color: #AEDE3E;
    color: #000000;
    /* color: unset !important; */
    /* background: unset !important; */
    /* border: none !important; */
}

.btn {
    background-color: #AEDE3E;
    border-color: #AEDE3E;
    color: #313D15;
}

.btn:hover {
    background-color: #AEDE3E;
}

.btn:hover {
    background-color: #AEDE3E;
}

label {
    font-family: "M PLUS 1", sans-serif;
    ;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #313D15;
}

.form-control {
    border: 1px solid #DADEDA;
    background-color: #FFFFFF;

}

.input-group-text {
    background-color: #FFFFFF;
}

p {
    font-family: "M PLUS 1", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 28.96px;
    text-align: left;
}

.detail-icon {
    width: 24px;
    height: 20px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    justify-content: center;
    align-items: center;
    background-color: #AEDE3E;
    cursor: pointer;
}

.table-responsive {
    border-radius: 13px;
}

/* input[type="file"]{
    display: none;
  } */
label {
    cursor: pointer;
}

/* input {
    margin-top: 1rem;
  } */

input::file-selector-button {
    display: none;
}

li:active {
    background-color: #DADEDA;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.app {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e9f3fe;
}

/* .parent {
    width: 172px;
    margin: auto;
    padding: 2rem;
    background: #ffffff;
    border-radius: 25px;
    box-shadow: 7px 20px 20px rgb(210, 227, 244);
  } */
.file-upload {
    text-align: center;
    border: 3px dashed #DADEDA;
    cursor: pointer;
    background-color: #F9FAF5;
    justify-content: center;
    align-items: center;
    width: 100px;
    padding-top: 10px;
}

.file-upload p {
    font-size: 0.87rem;
    margin-top: 10px;
    color: #bbcada;
}

 .file-upload input {
    /* display: block; */
    /* height: 100%; 
    width: 100%; */
    /* position: absolute; */
    /* top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
    opacity: 0;
    cursor: pointer;
} 

.Tab a:hover,
.Tab a:active {
    color: #34b739;
    border-bottom: #34b739;
}

/* .custom-arrow {
    position: absolute;
    left: 549px;
    top: 411px
} */

.table-wrapper {
    max-width: 600px;
    /* Adjust the maximum width of the table wrapper */
    margin: 0 auto;
    /* Center align the table */
}

.table th,
.table td {
    vertical-align: middle;
    /* Align table cell content vertically */
    padding-left: 40px;
    /* Adjust cell padding */
}

.webinar-result {
    background-color: #FF547D;
    color: #59192C;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 4px;
    padding-top: 4px;
    border-radius: 10px;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.webinar-result-ongoing {
    background-color: #54EAFF;
    color: #59192C;
    padding-left: 10px;
    padding-right: 21px;
    padding-bottom: 4px;
    padding-top: 4px;
    border-radius: 10px;
    justify-content: center;
    cursor:pointer;
}

.webinar-image {
    /* position: absolute; */
    /* top: 130px; */
    /* left: 476px; */
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 37.18%, #000000 100%);
    border-radius: 11px;
}

li.active {
    background-color: #E0E5D1;
    /* padding: 5px;
    border-radius: 7px; */
}

.product-image {
    /* position: absolute; */
    /* left: 484px;  */
    /* left: 188px;
    top: -44px;
    border-radius: 10px;
    border: 1px solid #DADEDA;
    display: block; */
        border-radius: 10px;
        border: 1px solid #DADEDA;
        display: block;
        margin-bottom: 10px; 
    
    
}

.bread-crumb {
    font-family: "M PLUS 1";
    font-size: 15px;
    font-weight: 500;
    line-height: 21.72px;
    text-align: left;
}

span.active {
    border-bottom: 3px solid #8EB533;
}

.commonModel .modal-dialog {
    margin-top: 176px;
    width: 260px;
}

.modal-content {
    border-radius: 24px;
}

.modal-footer {
    border-top: 0px solid !important;
}

.log-out-text {
    font-family: "M PLUS 1";
    font-size: 25px;
    font-weight: 700;
    line-height: 35px;
    text-align: center;
    color: #313D15;
}

.log-out-footer {
    font-family: "Manrope";
    font-size: 20px;
    font-weight: 500;
    line-height: 27.32px;
    text-align: center;
    color: #838779;
}

.common-model-add-winner .modal-dialog {
    margin-top: 176px;
    width: 260px;
}

.modal-title {
    font-family: "M PLUS 1";
    font-size: 25px;
    font-weight: 600;
    line-height: 36.2px;
    text-align: left;
    color: #313D15
}

.close-icon {
    background-color: #D0182E;
    padding: 8px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
     left: 500px;
    /* top: 136px; */
    top: 145px;
    /* right: 71%;
    top: 33%; */
    color: white;
}

.fixTableHead {
    /* overflow-y: auto; */
    height: 70vh;
} 

.fixTableHead thead th {
    position: sticky;
    top: 0;
}
/* 
.fixTableHead tbody td{
    overflow-inline: auto;
} */

.table {
   margin: 0px !important;
} 

.bar {
    color: #D6D9CD
}

.booking-card-text {
    font-family: "M PLUS 1";
    font-size: 13px;
    font-weight: 600;
    line-height: 18.82px;
    text-align: left;
    color: #313D15
}

.booking-card-status {
    font-family: M PLUS 1;
    font-size: 13px;
    font-weight: 600;
    line-height: 18.82px;
    text-align: left;
    color: #313D15;
}

.offer-input{
    width: 125px;
    height: 90px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 35px 0px #00000026;
    border-radius: 9px;
    padding: 6px;
    float: right;
    position: absolute;
    left: 350px;
    top: 34px;

}

@media only screen and (max-width: 600px) {
    .main {
        height: auto; 
        width: 90%; 
        max-width: none; 
    }
}
.winner-number{
    background-color: #FFBB54;
    border-radius:3px ;
    padding-left: 10px;
    padding-right: 20px;
    padding-bottom: 4px;
    padding-top: 4px;
    border-radius: 10px;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.layout-container{
   max-width: 973px;
    background-color: #FFFFFF;
    margin: 0px 30px 30px 0px;
    box-shadow: "0px 0px 24px 0px rgba(0,0,0,0.12)"
}

.create-webinar-form{
    margin-bottom: 10px;
}
.showpassbtn{
   display: flex !important;
   justify-content: end;
   margin-top: -2rem;
   margin-left: 95%;
   margin-right: 2%;
}
.rdw-link-modal-target-option{
    margin-bottom: 3px !important;
}

.remove-icon {
    position: relative;
    top: -77px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    left: 50px;
    /* left: 106px; */
    justify-self: center;
    background-color: #D0182E;
    color: #FFFFFF;
    padding: 6px;
}

.form-select{
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='50' height='50' viewBox='0 0 32 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32' height='40' rx='2' fill='%23AEDE3E' /%3E%3Cpath d='M20 12L16 16L12 12' stroke='%23313D15' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
    background-size: 28px auto;
}
.container-fluid{
    height: auto;
    margin-bottom: 20px;
}

.table>:not(:last-child)>:last-child>*{
    border-bottom-color: none !important;
}
.showpassbtn-reset{
    display: flex !important;
    justify-content: end;
    margin-top: -2rem;
    margin-left: 89%;
    margin-right: 2%;
 }
 .form-check-input{
    cursor: pointer;
 }

 li{
    width: 100%;
    border-radius: 7px;
    padding-left: 8%; 
    padding-top: 1%; 
    padding-bottom: 2%; 
 }

 .pagination {
    position: sticky;
    border-radius: 7px;
    padding: 0px !important;
    width: 20%;
    bottom: 0;
    background-color: white; /* Adjust as needed */
    padding: 10px;
    top:-4%
  }

  .button-prv{
    border: none;
  }
  .page-item{
    padding-left: 0px !important;
    background-color: white;
    margin-top: 2px;
    margin-left: 5px;
  }
  .page-item .page-link{
    border-radius: 50px !important;
    color: #34b739;
  }
  .page-item.active .page-link{
    border-radius: 50px !important;
    color: white;
    background-color: #34b739;
    border-color: #34b739;
  }
  li.page-item.active{
    background-color: #FFFFFF;
  }